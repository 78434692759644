import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import _ from "lodash"
import { navigate } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import differenceInMinutes from "date-fns/differenceInMinutes"
import differenceInDays from "date-fns/differenceInDays"

import { withTranslationHOC } from "../i18n/with-translation"
import {
  ButtonGroup,
  Centered,
  FlexCentered,
  Footer,
  IconButton,
  Loader,
  MapContainer,
  MenuItem,
  MiddleMenu,
  Notification,
  NotificationContent,
  NotificationText,
  PageContainer,
  Text,
  Title,
  TopBar,
  TopMenu,
} from "../components"
import { initialDamage } from "../data/reducers/damage"
import { useLanguage, useMap, useSimulation, useWindowSize } from "../hooks"
import { formatNumber, plural } from "../utils"
import { setSimulation } from "../data/actions"

const ResultContainer = styled.div`
  position: relative;
  width: 300px;
`

const FlexCenteredWidth = styled(FlexCentered)`
  width: 100%;
`

function extractBooleanFromString(value) {
  if (value === "false") {
    return false
  }

  if (value === "true") {
    return true
  }

  return parseInt(value, 10)
}

function extractDamageFromUrl() {
  const params = new URL(document.location).searchParams
  const paramsDamage = {}
  params.forEach((value, key) => {
    paramsDamage[key] = extractBooleanFromString(value)
  })

  const isClean = Object.keys(initialDamage).reduce((result, next) => {
    if (result) {
      return Object.prototype.hasOwnProperty.call(paramsDamage, next)
    }

    return false
  }, true)

  return isClean ? paramsDamage : null
}

function Start() {
  const dispatch = useDispatch()
  const { t, lang } = useLanguage()
  const [isLoaded, setLoaded] = useState(false)

  const damage = useSelector(({ damage }) => damage)
  const { status, victimOnRegion, deathsOnRegion } = damage
  const { isMobile } = useWindowSize()

  useEffect(() => {
    const paramsDamage = extractDamageFromUrl()

    if (paramsDamage) {
      dispatch(setSimulation(paramsDamage))
    }
  }, [dispatch])

  useEffect(() => {
    const paramsDamage = extractDamageFromUrl()

    if (paramsDamage) {
      return
    }

    if (_.isEqual(damage, initialDamage)) {
      navigate("/")
    }
  }, [damage])

  const { map, mapObject } = useMap({ setLoaded })
  const {
    values,
    handleStart,
    handlePause,
    handleFinish,
    handleReset,
    needToReset,
  } = useSimulation({
    isLoaded,
    mapObject,
  })

  const minutes = differenceInMinutes(values.time, Date.now())
  const minutesDifference = minutes > 0 ? minutes : 0

  const days = differenceInDays(values.time, Date.now())
  const daysDifference = days > 0 ? days : 0

  return (
    <PageContainer>
      <Helmet>
        <title>{t("start.title")}</title>
      </Helmet>
      {!isLoaded && <TopBar />}

      <MapContainer ref={map} isLoaded={isLoaded} />
      {!isLoaded && (
        <Centered>
          <Loader />
        </Centered>
      )}

      <TopMenu>
        {!isMobile && <Title fontSize={24}>{t("start.simulation")}</Title>}
        <ButtonGroup>
          <IconButton
            onClick={handleStart}
            iconName="play"
            disabled={needToReset || !isLoaded}
          />
          <IconButton
            onClick={handleFinish}
            iconName="forward"
            disabled={needToReset || !isLoaded}
          />
          <IconButton
            onClick={handlePause}
            iconName="pause"
            disabled={needToReset || !isLoaded}
          />
          <IconButton
            onClick={handleReset}
            iconName="stop"
            disabled={!isLoaded}
            needToPress={needToReset}
          />
        </ButtonGroup>
      </TopMenu>

      <MiddleMenu>
        <ResultContainer>
          <MenuItem
            title={t("start.info.deaths", {
              deaths: formatNumber(String(values.deaths)),
            })}
          />
          <Notification mode="error" skipHeightCheck>
            <NotificationContent>
              <NotificationText>
                {t("start.notification.text")}
              </NotificationText>
              <br />
              {status && (
                <>
                  <NotificationText>
                    {t("start.notification.status")}
                  </NotificationText>
                  <br />
                  <NotificationText>
                    {t("start.notification.victim", {
                      victimOnRegion: formatNumber(String(victimOnRegion)),
                    })}
                  </NotificationText>
                  <NotificationText>
                    {t("start.notification.deaths", {
                      deathsOnRegion: formatNumber(String(deathsOnRegion)),
                    })}
                  </NotificationText>
                </>
              )}
            </NotificationContent>
          </Notification>
        </ResultContainer>
        <ResultContainer>
          <MenuItem
            title={t("start.info.victim", {
              victim: formatNumber(String(values.victim)),
            })}
          />
        </ResultContainer>
        <ResultContainer>
          <MenuItem
            title={
              <FlexCenteredWidth>
                <Text>
                  {t("start.info.time", {
                    time: `${formatNumber(String(minutesDifference))} ${plural(
                      minutesDifference,
                      {
                        ru: ["минута", "минуты", "минут"],
                        en: ["minute", "minutes", "minutes"],
                      },
                      lang
                    )}`,
                  })}
                </Text>
                <Text>
                  {formatNumber(String(daysDifference))}
                  {plural(
                    daysDifference,
                    {
                      ru: ["день", "дня", "дней"],
                      en: ["day", "days", "days"],
                    },
                    lang
                  )}
                </Text>
              </FlexCenteredWidth>
            }
          />
        </ResultContainer>
      </MiddleMenu>

      <Footer status={isLoaded ? t("status.done") : t("status.refresh")} />
    </PageContainer>
  )
}

export default withTranslationHOC(Start)
